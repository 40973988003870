.muted {
  color: #fcd6d6;
}

b {
  color: #000;
}

.hycli-header {
  display: flex;
  justify-content: space-between;
}

.hycli-header a {
  text-decoration: none;
}

.hycli-action-container {
  width: 100%;
  max-width: 800px;
}
